import React from "react"
import ReactMarkdown from "react-markdown"
import { CircularProgress, TextField, Button, Alert } from "@mui/material"

import { FaThumbsDown } from "react-icons/fa"
import { IoMdClose } from "react-icons/io"
import "./Bot.css"
import rehypeRaw from "rehype-raw";
import { sendFeedback } from "./utils"

function LinkRenderer(props) {
  return (
    <a href={props.href} target="_blank" rel="noreferrer">
      {props.children}
    </a>
  )
}

const Bot = ({ botText, loading, conversationId, showFeedback }) => {
  const [feedback, setFeedback] = React.useState(null)
  const [feedbackText, setFeedbackText] = React.useState(null)
  const [sendingFeedback, setSendingFeedback] = React.useState(false)
  const [messageType, setMessageType] = React.useState(null)

  const handleSubmit = async () => {
    setSendingFeedback(true)
    console.log(feedbackText)
    await sendFeedback(conversationId, feedbackText)
    setFeedback(false)
    setMessageType("success")
    setTimeout(() => {
      setMessageType(null)
    }, 3000)
  }
  return (
   <div className="div-vertical">
      <div className="bot">
        <img className="bot-logo" src={`${process.env.PUBLIC_URL}${"assets/bot.png"}`} alt="bot" />
        {loading && botText === "" && <CircularProgress size={20} />}
        <div className="bot-text text-area">
        <ReactMarkdown children={botText} components={{ a: LinkRenderer }} rehypePlugins={[rehypeRaw]} />
        </div>
        { showFeedback && (
          <FaThumbsDown
            className="thumbs-down-icon"
            color="#888"
            size={18}
            onClick={() => setFeedback(true)}
          />
        )}
      </div>
      {!feedback && messageType === "success" && (
        <Alert severity="success">Feedback sent successfully.</Alert>
      )}
      {feedback && (
        <div className="div-feedback">
          {messageType === "error" && <Alert sx={{width:"fit-content", margin:"auto"}} severity="error">Error sending feedback.</Alert>}
          <div style={{ display: "flex" }}>
            <TextField
              id="outlined-textarea"
              label="Feedback..."
              placeholder="Placeholder"
              multiline
              value={feedbackText ? feedbackText : ""}
              onChange={(e) => setFeedbackText(e.target.value)}
              sx={{ width: "100%" }}
            />
            <IoMdClose
              style={{ cursor: "pointer" }}
              color="#000"
              size={22}
              onClick={() => setFeedback(false)}
            />
          </div>
          <div className="submit-feedback">
            {sendingFeedback ? (
              <CircularProgress size={24} />
            ) : (
              <Button
                variant="contained"
                onClick={handleSubmit}
                sx={{ padding: "2px 6px", fontSize: "12px" }}
              >
                Submit
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default Bot
