export const getConversationType = () => {
  return localStorage.getItem("conversation_type")
}
export const getConversationId = async () => {
  try {
    const body = JSON.stringify({ channel: getConversationType() })
    const response = await fetch(`/common/create_conversation`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    })
    const data = await response.json()
    return data["id"]
  } catch (error) {
    return ""
  }
}

export const getBotResponse = async (conversationId, message) => {
  try {
    const token = localStorage.getItem("reece_auth_token")
    const response = await fetch(
      `${getConversationType()}/chat-agent`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          conversation_id: conversationId,
          message: message,
        }),
      }
    )
    const data = await response.json()
    return data
  } catch (error) {
    return {
      response:
        "**Sorry for the inconvenience, I have encountered an error. Please try again later!**",
    }
  }
}

export const getBotResponseStream = async (conversationId, message) => {
  try {
    const token = localStorage.getItem("reece_auth_token")
    const response = await fetch(
      `${getConversationType()}/stream`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          conversation_id: conversationId,
          message: message,
        }),
      }
    )
    if (response.status === 401)
      return {
        response: "**Please [Log In](/authentication/signin) to continue the conversation**",
      }
    const reader = response.body.getReader()
    return reader
  } catch (error) {
    return {
      response:
        "**Sorry for the inconvenience, I have encountered an error. Please try again later!**",
    }
  }
}

export const validateUser = async (token) => {
  try {
    const response = await fetch("/authentication/validation", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: token }),
    })

    if (response.ok) return true
    else if (response.status === 403) return false
    else return false
  } catch (error) {
    return false
  }
}

export const sendFeedback = async (conversationId, feedbackText) => {
  try {
    const token = localStorage.getItem("reece_auth_token")
    const response = await fetch(`/common/submit_feedback`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        conversation_id: conversationId,
        feedback_msg: feedbackText,
      }),
    })
    const data = await response.json()
    return data
  } catch (error) {
    console.log(error)
  }
}